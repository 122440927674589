import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Sword(props) {
        const { nodes, materials } = useGLTF('/models/sword.glb')
        return (
            <group {...props} dispose={null}>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1821133275.geometry}
                        material={materials.mat9}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1968882272.geometry}
                        material={materials.mat9}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1959712594.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group294009847.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group903144503.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1760382364.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1079366371.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group707763807.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1342537370.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1853748403.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1491273729.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group429069021.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group198824051.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group262363045.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group891422983.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1165729818.geometry}
                        material={materials.mat9}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group570706764.geometry}
                        material={materials.mat9}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1337980982.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2032641331.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1601436815.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1107817674.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1401560471.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1614188583.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1752017647.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group957386127.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group263190879.geometry}
                        material={materials.mat9}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2068738392.geometry}
                        material={materials.mat16}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1406432808.geometry}
                        material={materials.mat16}
                    />
                    <directionalLight intensity={1} decay={2} />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_28.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_28_1.geometry}
                        material={materials.mat4}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_28_2.geometry}
                        material={materials.mat21}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_28_3.geometry}
                        material={materials.mat17}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_28_4.geometry}
                        material={materials.mat15}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_28_5.geometry}
                        material={materials.mat14}
                    />
            </group>
        )
}

useGLTF.preload('/models/sword.glb')

export default Sword;