import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Cabin(props) {
        const { nodes, materials } = useGLTF('/models/cabin.glb')
        return (
            <group {...props} dispose={null}>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group672332923.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1969926230.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1666337402.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2068593617.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group703600248.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1633857655.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group104304224.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1174076508.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1967756855.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1801783179.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group834835194.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group931828629.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group160018589.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group835746257.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2091678014.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group137386681.geometry}
                        material={materials.mat17}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group708783982.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group213256307.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1731019520.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group959498058.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group305237793.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1907070200.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group982949776.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group666745333.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1586947889.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1815555598.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group747392880.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2105121235.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1949620223.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group546902528.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group2127864345.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1899937043.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group368297890.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1717435439.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group549259774.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group883306875.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1785257046.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1447639213.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1075972180.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group949739094.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group534790539.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group912469926.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group584635605.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1634337996.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group99166187.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group966020665.geometry}
                        material={materials.mat22}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group562969503.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.group1499613590.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_0.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_0_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_1.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_1_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_2.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_2_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_8.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_8_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_11.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_11_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_12.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_12_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_14.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_14_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_15.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_15_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_16.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_16_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_20.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_20_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_21.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_21_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_30.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_30_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_31.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_31_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_38.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_38_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_39.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_39_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_40.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_40_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_46.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_46_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_47.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_47_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_48.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_48_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_49.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_49_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_50.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_50_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_51.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_51_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_52.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_52_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_53.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_53_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_54.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_54_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_55.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_55_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_56.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_56_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_57.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_57_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_58.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_58_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_59.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_59_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_60.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_60_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_61.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_61_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_62.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_62_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_63.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_63_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_64.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_64_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_65.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_65_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_66.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_66_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_67.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_67_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_68.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_68_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_83.geometry} material={materials.mat22} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_83_1.geometry}
                        material={materials.mat23}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_84.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_84_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_85.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_85_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_86.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_86_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_87.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_87_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_90.geometry} material={materials.mat17} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_90_1.geometry}
                        material={materials.mat16}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_91.geometry} material={materials.mat16} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_91_1.geometry}
                        material={materials.mat17}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_92.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_92_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_93.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_93_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_94.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_94_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_95.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_95_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_96.geometry} material={materials.mat18} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_96_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_97.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_97_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh castShadow receiveShadow geometry={nodes.mesh_99.geometry} material={materials.mat20} />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_99_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_99_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_99_3.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_100.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_100_1.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_101.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_101_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_102.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_102_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_104.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_104_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_105.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_105_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_105_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_105_3.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_106.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_106_1.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_106_2.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_106_3.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_107.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_107_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_107_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_107_3.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_108.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_108_1.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_108_2.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_108_3.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_109.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_109_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_110.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_110_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_111.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_111_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_112.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_112_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_113.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_113_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_114.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_114_1.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_114_2.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_114_3.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_115.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_115_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_115_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_115_3.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_116.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_116_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_116_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_116_3.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_117.geometry}
                        material={materials.mat18}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_117_1.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_117_2.geometry}
                        material={materials.mat3}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_117_3.geometry}
                        material={materials.mat12}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_118.geometry}
                        material={materials.mat20}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.mesh_118_1.geometry}
                        material={materials.mat18}
                    />
            </group>
        )
}

useGLTF.preload('/models/cabin.glb')

export default Cabin;